import { UmbracoTypes } from '~/lib/data-contract';
import { usePage } from '~/services';
import DynamicBlocks from '~/templates/blocks';
import { Container } from './styles';

type FrontPages = UmbracoTypes.IP20FrontPage | UmbracoTypes.IP21FrontPageLouisPoulsen;

export default function P20FrontPage() {
    const { data: page } = usePage<FrontPages>();

    return (
        <Container>
            <DynamicBlocks elements={page?.pageElements as UmbracoTypes.IModules[]} />
        </Container>
    );
}
