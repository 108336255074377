import dynamic from 'next/dynamic';
import { UmbracoTypes } from '~/lib/data-contract';
import { ModuleContext } from './context/ModuleContext';
import { Blocks } from './types';

const blocks: Blocks = {
    m10HeroModule: dynamic(() => import('./components/M10Hero')),
    m11HeroSplitModule: dynamic(() => import('./components/M11HeroSplit')),
    m20CategoriesModule: dynamic(() => import('./components/M20Categories')),
    m21ReferencedCategoriesModule: dynamic(() => import('./components/M21ReferencedCategories')),
    m30PopularProductCarouselModule: dynamic(
        () => import('./components/M30ProductCarousel/PopularProductCarousel'),
    ),
    m30BrandProductCarouselModule: dynamic(
        () => import('./components/M30ProductCarousel/BrandProductCarousel'),
    ),
    m30CampaignProductCarouselModule: dynamic(
        () => import('./components/M30ProductCarousel/CampaignProductCarousel'),
    ),
    brandPicker: dynamic(() => import('./components/M45BrandOverview/BrandOverview')),
    m60UspModule: dynamic(() => import('./components/M60USP')),
    m70MediaModule: dynamic(() => import('./components/M70Media')),
    m75MediaAndTextModule: dynamic(() => import('./components/M75MediaText')),
    m76ContentPromotionModule: dynamic(() => import('./components/M76ContentPromotion')),
    m80MediaAndTextBundleModule: dynamic(() => import('./components/M80MediaTextBundle')),
    m100RichTextModule: dynamic(() => import('./components/M100RichText')),
    m120AccordionListModule: dynamic(() => import('./components/M120Accordion')),
    DynamicCategoryListBlock: dynamic(() => import('./components/DynamicCategoryListBlock')),
    m200OrderHistory: dynamic(() => import('./components/M200OrderHistory')),
    m201OrderDetails: dynamic(() => import('./components/M201OrderDetails')),
    m202AccountInfo: dynamic(() => import('./components/M202AccountInformation')),
    m203AccountStatement: dynamic(() => import('./components/M203AccountStatement')),
    m204UserList: dynamic(() => import('./components/M204UserList')),
    m205UserProfile: dynamic(() => import('./components/M205UserProfile')),
};

function Block({ element }: { element: UmbracoTypes.IModules }) {
    const Block = blocks[element.type as keyof Blocks] || Noop;
    return <Block {...(element as object)} />;
}

function Noop({ children }: { children: React.ReactNode }) {
    return <>{children}</>;
}

export default function DynamicBlocks({
    elements,
}: {
    elements: UmbracoTypes.IModules[] | undefined;
}) {
    return (
        <>
            {elements
                ? elements.map((element, index) => (
                      <ModuleContext.Provider value={{ index }} key={element.id || index}>
                          <Block element={element} />
                      </ModuleContext.Provider>
                  ))
                : null}
        </>
    );
}
